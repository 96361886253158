import { Arrow, BgDone, Check } from "assets";
import styles from "./styles.module.scss";
import { Button } from "components";
import { useLocation, useNavigate } from "react-router-dom";

const CompletedUI = () => {
  const navigate = useNavigate();

  const { state } = useLocation();

  return (
    <div className={styles.bg}>
      <div className={styles.content}>
        <div className={styles.content__done}>
          <Check />
          <h1>All done, yay!</h1>
          <p>You've officially set sail with us.</p>
          <Button variant="fill-blue" onClick={() => navigate("/")}>
            Back to home
          </Button>
        </div>

        {state?.sector === "Education Travel" && (
          <div className={styles.content__newtoVobb}>
            <BgDone className={styles.pattern} />
            <span className={styles.span}>New to Vobb?</span>
            <h2>Checkout Atlas</h2>
            <p>The application portal for education agents</p>
            <a href="http://atlas.vobb.io" target="_blank" rel="noopener noreferrer">
              Go to Atlas <Arrow className={styles.icon} />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export { CompletedUI };
