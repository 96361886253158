import { Section, Title } from "components";
import styles from "./styles.module.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const CaseStudies = () => {
  const slideUp1 = useRef(null);
  const slideUp2 = useRef(null);

  useGSAP(
    () => {
      gsap.from(slideUp1.current, {
        duration: 1,
        opacity: 0,
        y: 120,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideUp1.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideUp1 }
  );

  useGSAP(
    () => {
      gsap.from(slideUp2.current, {
        duration: 1,
        opacity: 0,
        y: 120,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideUp2.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideUp2 }
  );
  return (
    <Section bgClassName={styles.bg} sectionClassName={styles.contentbg}>
      <Title variant="small">Case Study</Title>
      <div className={styles.content}>
        <div ref={slideUp1} className={styles.content__left}>
          <h4 className={styles.content__bigHeader}>
            TravStar uses Vobb to monitor and manage bookings.
          </h4>
          <div className={styles.content__badges}>
            <span>Vobb Atlas</span>
            <span>Pipeline</span>
            <span>Finance</span>
            <span>Sendup</span>
            <span>Sales</span>
          </div>
        </div>
        <div ref={slideUp2} className={styles.content__right}>
          <Title variant="small" className={styles["content__right--title"]}>
            TraveStar is a tour operations agency that specializes in selling international tour
            experiences to tourists from over 50 countries.
          </Title>
          <div className={styles.challenges}>
            <Title variant="small" className={styles["content__right--title2"]}>
              The Challenge
            </Title>
            <p>
              In the past, managing our tour operations posed significant challenges as we grappled
              with manual systems, relying on scattered information across different platforms.
              Coordinating diverse tour packages, handling bookings, and ensuring seamless
              communication with our clients demanded a more streamlined approach
            </p>
          </div>
          <div className={styles.results}>
            <Title variant="small" className={styles["content__right--title2"]}>
              The Results
            </Title>
            <p>
              Since integrating Vobb into our operations, the transformation has been nothing short
              of remarkable. Vobb's system has revolutionized our workflow, now we have a
              centralized system for managing tours effortlessly. From itinerary creation to booking
              accommodations and handling transportation logistics.
            </p>
            <p>
              Our team also now have real-time visibility into every tour detail, allowing us to
              enhance customer satisfaction and elevate our services to new heights.
            </p>
          </div>

          <div className={styles.stats}>
            <div>
              <Title variant="small" className={styles["content__right--title3"]}>
                30%
              </Title>
              <p>Increase in customer satisfaction</p>
            </div>
            <div>
              <Title variant="small" className={styles["content__right--title3"]}>
                360°
              </Title>
              <p>View of all tour itineraries</p>
            </div>
            <div>
              <Title variant="small" className={styles["content__right--title3"]}>
                200+
              </Title>
              <p>More tour packages accessible for us to offer our clients</p>
            </div>
            <div>
              <Title variant="small" className={styles["content__right--title3"]}>
                40%
              </Title>
              <p>Reduction in time spent on manual coordination</p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export { CaseStudies };
