import { Section, Title } from "components";
import styles from "./styles.module.scss";
import { CountriesTour } from "assets";
import { useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const SellInternationally = () => {
  const zoomIn = useRef(null);
  const slideUp = useRef(null);

  useGSAP(
    () => {
      gsap.from(slideUp.current, {
        duration: 1,
        opacity: 0,
        y: 90,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideUp.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideUp }
  );

  useGSAP(
    () => {
      gsap.from(zoomIn.current, {
        duration: 1,
        opacity: 0,
        scaleX: 0.5,
        scaleY: 0.5,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: zoomIn.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: zoomIn }
  );
  return (
    <Section bgClassName={styles.bg} sectionClassName={styles.contentbg}>
      <div ref={slideUp} className={styles.contentTop}>
        <Title variant="small">Sell internationally</Title>
        <h2 className={styles.contentTop__bigHeader}>
          Start and scale your travel business with ease.
        </h2>
        <p className={styles.contentTop__paragraph}>
          Gain access to internationally available tour packages, events, locations, discounts and
          more! all from one dashboard.
        </p>
        <p className={styles.contentTop__ttl}>Packages from over +100 countries</p>
      </div>

      <div ref={zoomIn} className={styles.countries}>
        <CountriesTour className={styles.countries__img} />
      </div>
    </Section>
  );
};

export { SellInternationally };
