export function shortenText(
  text: string | undefined,
  maxLength: number,
  truncationIndicator: string = "..."
): string {
  if (text && text.length <= maxLength) {
    return text ?? "";
  }

  const cutoff = text?.slice(0, maxLength - truncationIndicator.length + 1).lastIndexOf(" ");
  const adjustedLength = cutoff && cutoff > 0 ? cutoff : maxLength - truncationIndicator.length;

  return text?.slice(0, adjustedLength) + truncationIndicator;
}
