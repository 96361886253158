import { Section } from "components";
import styles from "./styles.module.scss";
import { HeroStroke, MobileOverviewMockup, OverviewMockup } from "assets";
import { Link } from "react-router-dom";
import { Routes } from "router";
import { useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(useGSAP);

const HeroSection = () => {
  const imageSec = useRef(null);
  const contentSec = useRef(null);

  useGSAP(
    () => {
      gsap.from(contentSec.current, {
        duration: 1,
        opacity: 0,
        y: 90,
        ease: "power1.out",
        willChange: "transform"
      });
    },
    { scope: contentSec }
  );

  useGSAP(
    () => {
      gsap.from(imageSec.current, {
        duration: 1,
        scaleX: 0.5,
        scaleY: 0.5,
        ease: "power1.out",
        willChange: "transform"
      });
    },
    { scope: imageSec }
  );

  return (
    <>
      <Section bgClassName={styles.heroBg} sectionClassName={styles.hero}>
        <div ref={contentSec} className={styles.header}>
          <h1 className={styles.header__ttl}>
            Operating System for Travel Agents
            <HeroStroke className={styles.svgStroke} />
          </h1>
          <p className={styles.header__txt}>
            The essential tools to power your travel business, automate your marketing and
            efficiently facilitate travels.
          </p>
          <div className={styles.header__btns}>
            <Link to={Routes.earlyAccess} className={styles.accessLink}>
              Get early access
            </Link>
            <a
              href="https://atlas.vobb.io/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.atlasLink}>
              Visit Atlas
            </a>
          </div>
        </div>
      </Section>
      <Section innerRef={imageSec} sectionClassName={styles.imgSec}>
        <OverviewMockup className={styles.imgSec__desktop} />
        <MobileOverviewMockup className={styles.imgSec__mobile} />
      </Section>
    </>
  );
};

export { HeroSection };
