import styles from "./styles.module.scss";
import { ReactElement, useRef, useState } from "react";
import { Arrow, BooksMockup, PipelineMockup, SalesMockup, SendupMockup } from "assets";
import { Link } from "react-router-dom";
import { Title } from "components";
import { Routes } from "router";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useNavContext } from "context";

gsap.registerPlugin(useGSAP, ScrollTrigger);

interface ProductSuitesData {
  title: string;
  description: string;
  paragraph: string;
  linkText?: string;
  color: string;
  url: string;
  image: ReactElement<SVGElement>;
}

const carouselItems: ProductSuitesData[] = [
  {
    title: "Pipeline",
    description: "Attract and Manage Exciting Travellers.",
    paragraph:
      "From lead generation, to scoring, to conversion, Vobb’s lead management tools ensures proper follow-up to move your leads through the sales pipeline.",
    color: "#D11660",
    url: "pipeline",
    image: <PipelineMockup />
  },
  {
    title: "Sales",
    description: "Create and display your packages & offerings.",
    paragraph:
      "Beautifully display your offerings on a hosted offerings page for best customer experience, better conversion, and insightful analytics.",
    color: "#00C3A2",
    url: "sales",
    image: <SalesMockup />
  },
  {
    title: "Atlas",
    description: "Access Global Education Providers.",
    paragraph:
      "Build and Scale your education travel consultancy business by Leveraging our access to over 450 prestigious and sort after Universities and programs around the world.",
    linkText: "Explore product",
    color: "#FA2727",
    url: "https:atlas.vobb.io/",
    image: <BooksMockup />
  },
  {
    title: "Books",
    description: "Collect and manage Payments.",
    paragraph:
      "Create and issue invoices, collect payments, monitor and keep track of mark-ups and commissions across your offerings and services. ",
    color: "#35B93F",
    url: "books",

    image: <BooksMockup />
  },
  {
    title: "Sendup",
    description: "Sales and Marketing work better together",
    paragraph:
      "Design and send professional emails independently, and optimize to improve performance powered by your pipeline database, your data for targeting and customisation is always accurate.",
    color: "#F4511E",
    url: "sendup",
    image: <SendupMockup />
  }
];

const ProductsSlider = () => {
  const component = useRef(null);
  const slider = useRef(null);
  const { productRef } = useNavContext();


  useGSAP(() => {
    let ctx = gsap.context(() => {
      const curr: any = slider.current;
      let panels = gsap.utils.toArray(".panel");

      gsap.to(panels, {
        xPercent: -100 * (panels.length - 1),
        ease: "none",
        willChange: "transform",
        scrollTrigger: {
          trigger: slider.current,
          pin: true,
          scrub: 1,
          start: "top 15%",
          snap: 1 / (panels.length - 1),
          end: () => "+=" + curr.offsetWidth,
          // markers: true,
          invalidateOnRefresh: true
        }
      });
    }, component);
    return () => ctx.revert();
  }, []);

  return (
    <section ref={productRef}>
      <section ref={component} className={styles.content}>
        <div ref={slider} className={styles.content__carousel}>
          {carouselItems.map((item) => (
            <div key={item.title} className={`${styles.productsSlide} panel`}>
              <div className={styles.productLeft}>
                <Title variant="small" style={{ color: item.color }}>
                  {item.title}
                </Title>
                <h2 className={styles["productLeft--desc"]}>{item.description}</h2>
                <p className={styles["productLeft--paragraph"]}>{item.paragraph}</p>
                {item.linkText ? (
                  <a
                    href={item.url}
                    target="_blank"
                    rel="noreferrer"
                    className={styles["productLeft--link"]}
                    style={{ color: item.color }}>
                    <span>{item.linkText}</span>
                    <Arrow style={{ fill: item.color, marginBottom: "3px" }} />
                  </a>
                ) : (
                  <Link
                    to={Routes.earlyAccess}
                    className={styles["productLeft--link"]}
                    style={{ color: item.color }}>
                    <span>Get early access</span>
                    <Arrow style={{ fill: item.color, marginBottom: "3px" }} />
                  </Link>
                )}
              </div>
              <div className={styles.productRight}>{item.image}</div>
            </div>
          ))}
        </div>
      </section>
    </section>
  );
};

export { ProductsSlider };
