import { RefObject, useEffect } from 'react';

type CloseFunction = () => void;

export const useClickOutside = (
  ref: RefObject<HTMLElement>,
  closeFunction: CloseFunction
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        closeFunction && closeFunction();
      }
    };

    const handleScroll = () => {
      closeFunction && closeFunction();
    };

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [ref, closeFunction]);
};
