import { GetStartedPattern } from "assets";
import styles from "./styles.module.scss";
import { Section } from "components";
import { Link } from "react-router-dom";
import { Routes } from "router";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const GetStarted = () => {
  const zoomIn = useRef(null);

  useGSAP(
    () => {
      gsap.from(zoomIn.current, {
        duration: 1,
        scaleX: 0.5,
        scaleY: 0.5,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: zoomIn.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse",
          // markers: true
        }
      });
    },
    { scope: zoomIn }
  );
  return (
    <>
      <Section bgClassName={styles.bg} sectionClassName={styles.section}>
        <GetStartedPattern className={styles.patternLeft} />
        <div ref={zoomIn} className={styles.getStarted}>
          <p className={styles.getStarted__explore}>Explore Vobb</p>
          <p className={styles.getStarted__ttl}>Ready to get started?</p>
          <p className={styles.getStarted__txt}>
            Be a part of the top travel businesses engaging and connecting with their customers
            using Vobb.{" "}
          </p>
          <Link to={Routes.earlyAccess} className={styles.getStarted__link}>
            Get early access
          </Link>
        </div>
        <GetStartedPattern className={styles.patternRight} />
      </Section>
    </>
  );
};

export { GetStarted };
