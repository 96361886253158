import { Title, Section, WorldMap } from "components";
import styles from "./styles.module.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const DistributedTeam = () => {
  const zoomIn = useRef(null);
  const slideUp = useRef(null);

  useGSAP(
    () => {
      gsap.from(zoomIn.current, {
        duration: 1,
        scaleX: 0.5,
        scaleY: 0.5,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: zoomIn.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse",
          // markers: true
        }
      });
    },
    { scope: zoomIn }
  );

  useGSAP(
    () => {
      gsap.from(slideUp.current, {
        duration: 1,
        y: -90,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideUp.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse",
          // markers: true
        }
      });
    },
    { scope: slideUp }
  );

  return (
    <>
      <Section bgClassName={styles.bg} sectionClassName={styles.section}>
        <Title variant="small">About Us</Title>
        <h2 className={styles.section__heading}>We’re a distributed team</h2>
        <div ref={zoomIn}>
          <WorldMap />
        </div>
        <div ref={slideUp} className={styles.section__btm}>
          <div className={styles["section__btm--item"]}>
            <Title variant="small" className={styles.ttl}>
              Support
            </Title>
            <p className={styles.paragraph}>Our friendly team is here to help</p>
            <a href="mailto:support@vobb.io">support@vobb.io</a>
          </div>
          <div className={styles["section__btm--item"]}>
            <Title variant="small" className={styles.ttl}>
              Sales
            </Title>
            <p className={styles.paragraph}>Questions or queries? Get in touch!</p>
            <a href="mailto:sales@vobb.io">sales@vobb.io</a>
          </div>
          <div className={styles["section__btm--item"]}>
            <Title variant="small" className={styles.ttl}>
              Partnerships
            </Title>
            <p className={styles.paragraph}>Mon-Fri from 8am to 5pm</p>
            <a href="mailto:partnerships@vobb.io">partnerships@vobb.io</a>
          </div>
        </div>
      </Section>
    </>
  );
};

export { DistributedTeam };
