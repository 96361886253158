import axios from "axios";
import { Loader } from "components/loader";
import { EarlyAccessUI } from "modules/earlyAccess";
import React, { useState } from "react";

const EarlyAccess = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = ({ data, callback }) => {
    const SCRIPT_API_URL = process.env.REACT_APP_SCRIPT_API_URL;

    setLoading(true);
    const formData = new FormData();
    formData.append("First name", data.firstName);
    formData.append("Last name", data.lastName);
    formData.append("Email", data.email);
    formData.append("Number", data.phoneNumber);
    formData.append("Organisation", data.organisation);
    formData.append("Sector", data.sector.label);
    formData.append("Team size", data.teamSize.label);
    formData.append("Feature requests", data.features);

    if (!SCRIPT_API_URL) return;

    axios
      .post(SCRIPT_API_URL, formData)
      .then(() => {
        callback();
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Loader loading={loading} />
      <EarlyAccessUI handleSubmit={handleSubmit} />
    </>
  );
};

export { EarlyAccess };
