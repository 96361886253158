import { Title, Section } from "components";
import styles from "./styles.module.scss";
import { Accomodation, HotelsTour, TravelInsurance, UserEdu, Visa } from "assets";
import { useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const ManageAgency = () => {
  const slideUp = useRef(null);
  const slideUp1 = useRef(null);
  const slideUp2 = useRef(null);
  const slideUp3 = useRef(null);
  const slideUp4 = useRef(null);
  const zoomIn = useRef(null);

  const slideUpConfig = (ref) => {
    return {
      duration: 1,
      opacity: 0,
      y: 90,
      ease: "power1.out",
      willChange: "transform",
      scrollTrigger: {
        trigger: ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse"
        // markers: true
      }
    };
  };

  const zoomInConfig = (ref) => {
    return {
      duration: 1,
      opacity: 0,
      scaleX: 0.5,
      scaleY: 0.5,
      ease: "power1.out",
      willChange: "transform",
      scrollTrigger: {
        trigger: ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse"
        // markers: true
      }
    };
  };

  useGSAP(
    () => {
      gsap.from(zoomIn.current, zoomInConfig(zoomIn));
    },
    { scope: zoomIn }
  );

  useGSAP(
    () => {
      gsap.from(slideUp.current, slideUpConfig(slideUp));
    },
    { scope: slideUp }
  );

  useGSAP(
    () => {
      gsap.from(slideUp1.current, slideUpConfig(slideUp1));
    },
    { scope: slideUp1 }
  );
  useGSAP(
    () => {
      gsap.from(slideUp2.current, slideUpConfig(slideUp2));
    },
    { scope: slideUp2 }
  );
  useGSAP(
    () => {
      gsap.from(slideUp3.current, slideUpConfig(slideUp3));
    },
    { scope: slideUp3 }
  );
  useGSAP(
    () => {
      gsap.from(slideUp4.current, slideUpConfig(slideUp4));
    },
    { scope: slideUp4 }
  );

  return (
    <Section bgClassName={styles.bg} sectionClassName={styles.content}>
      <div ref={slideUp} className={styles.contentTop}>
        <Title variant="small">Manage your travel agency</Title>
        <h2 className={styles.contentTop__bigHeader}>
          A Robust suite of products enabling your business.
        </h2>
        <p className={styles.contentTop__paragraph}>
          The operating system is well-suited for small business and enterprise organizations alike;
          empowering large and small sales teams to be more effective.
        </p>
        <p className={styles.contentTop__paragraph}>
          CRM features like a <strong> Pipeline, Grouping, Sales,</strong> and{" "}
          <strong>automated marketing</strong> with smart segmentation.
        </p>
      </div>
      <div className={styles.contentBtm}>
        <div ref={slideUp1} className={styles.contentBtm__item}>
          <UserEdu />
          <p className={styles.title}>Acquire customers</p>
          <p>
            Vobb’s lead management tools ensures proper follow-up to move your leads through the
            sales pipeline.
          </p>
        </div>
        <div ref={slideUp2} className={styles.contentBtm__item}>
          <Visa />
          <p className={styles.title}>Visa regulations</p>
          <p>
            Access information on visa regulations and apply for your customers visa through the
            click of a button.
          </p>
        </div>
        <div ref={slideUp3} className={styles.contentBtm__item}>
          <TravelInsurance />
          <p className={styles.title}>Travel insurance</p>
          <p>
            Provide travel insurance to your customers through our insurance module with
            international providers.
          </p>
        </div>
        <div ref={slideUp4} className={styles.contentBtm__item}>
          <Accomodation />
          <p className={styles.title}>Accommodation</p>
          <p>
            Choose an appropriate option from over 1.8m hotels, guest houses, hostels, and
            apartments.
          </p>
        </div>
      </div>
      <img ref={zoomIn} src={HotelsTour} alt="hotels" className={styles.image} />{" "}
    </Section>
  );
};

export { ManageAgency };
