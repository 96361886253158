import { Button, CustomPhoneInput, Input, OptionType, SelectInput, TextArea } from "components";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Arrow, CompanyInfo, FeaturesInfo, PersonalInfo } from "assets";
import { useEarlyAccessContext } from "context";
import { useNavigate } from "react-router-dom";

interface EarlyAccessUIProps {
  handleSubmit: ({ data, callback }) => void;
}

const EarlyAccessUI: React.FC<EarlyAccessUIProps> = ({ handleSubmit }) => {
  const navigate = useNavigate();
  const { activeForm, handleFormChange } = useEarlyAccessContext();

  const progressBtns = ["personal", "company", "features"];

  const [personalData, setPersonalData] = useState<PersonalFormData | undefined>(undefined);
  const [companyData, setCompanyData] = useState<CompanyFormData | undefined>(undefined);
  const [featuresData, setFeaturesData] = useState<FeatureFormData | undefined>(undefined);

  return (
    <div className={styles.bg}>
      {activeForm === "personal" && (
        <Personal
          initData={personalData}
          submit={(data) => {
            setPersonalData(data);
            handleFormChange("company", ["personal"]);
          }}
        />
      )}

      {activeForm === "company" && (
        <Company
          initData={companyData}
          submit={(data) => {
            setCompanyData(data);
            handleFormChange("features", ["personal", "company"]);
          }}
        />
      )}

      {activeForm === "features" && (
        <Features
          initData={featuresData}
          submit={(data) => {
            setFeaturesData(data);
            handleFormChange("features", ["personal", "company"]);
            handleSubmit({
              data: { ...personalData, ...companyData, ...data },
              callback: () =>
                navigate("/completed", {
                  state: {
                    sector: companyData?.sector?.value
                  }
                })
            });
          }}
        />
      )}

      <div className={styles.btnWrap}>
        {progressBtns.map((btn) => (
          <div
            key={btn}
            className={`${styles.btnWrap__btn} ${
              btn === activeForm ? styles["btnWrap__btn--active"] : ""
            }`}></div>
        ))}
      </div>
    </div>
  );
};

export { EarlyAccessUI };

interface PersonalFormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

interface PersonalFormErrors {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
}

const initPersonalData: PersonalFormData = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: ""
};

interface PersonalFormProps {
  initData: PersonalFormData | undefined;
  submit: (data: PersonalFormData) => void;
}

const Personal: React.FC<PersonalFormProps> = ({ initData, submit }) => {
  const [state, setState] = useState(initPersonalData);
  const { firstName, lastName, email, phoneNumber } = state;
  const [error, setError] = useState<PersonalFormErrors>();

  useEffect(() => {
    initData && setState(initData);
  }, [initData]);

  const handleSubmit = () => {
    const errors: PersonalFormErrors = {};

    if (firstName.trim().length === 0) errors.firstName = "Required";
    if (lastName.trim().length === 0) errors.lastName = "Required";
    if (email.trim().length === 0) errors.email = "Required";
    if (phoneNumber.trim().length === 0) errors.phoneNumber = "Required";

    if (Object.keys(errors).length > 0) {
      setError(errors);
    } else {
      submit(state);
    }
  };

  return (
    <div className={styles.content}>
      <PersonalInfo className={styles.icon} />
      <div className={styles.details}>
        <h2>Personal information</h2>
        <p>Help us get to know you better</p>
      </div>
      <form className={styles.form}>
        <aside className={styles.formSection}>
          <div className={styles.form__flex}>
            <div>
              <Input
                name="firstName"
                label="First name"
                value={firstName}
                onChange={(e) => setState((prev) => ({ ...prev, firstName: e.target.value }))}
                error={error?.firstName}
              />
            </div>
            <div>
              <Input
                name="lastName"
                label="Last name"
                value={lastName}
                onChange={(e) => setState((prev) => ({ ...prev, lastName: e.target.value }))}
                error={error?.lastName}
              />
            </div>
          </div>
          <div>
            <Input
              name="email"
              label="Email"
              value={email}
              onChange={(e) => setState((prev) => ({ ...prev, email: e.target.value }))}
              error={error?.email}
            />
          </div>
          <div>
            <CustomPhoneInput
              label="Whatsapp number"
              value={phoneNumber}
              onChange={(value) => setState((prev) => ({ ...prev, phoneNumber: value }))}
              error={error?.phoneNumber}
            />
          </div>

          <Button variant="fill-blue" onClick={handleSubmit}>
            Continue
          </Button>
        </aside>
      </form>
    </div>
  );
};

interface CompanyFormData {
  organisation: string;
  sector: OptionType | null;
  teamSize: OptionType | null;
}

interface CompanyFormErrors {
  organisation?: string;
  sector?: string;
  teamSize?: string;
}

const initCompanyData: CompanyFormData = {
  organisation: "",
  sector: null,
  teamSize: null
};

interface CompanyFormProps {
  initData: CompanyFormData | undefined;
  submit: (data: CompanyFormData) => void;
}

const Company: React.FC<CompanyFormProps> = ({ initData, submit }) => {
  const { handleFormChange } = useEarlyAccessContext();

  const [state, setState] = useState<CompanyFormData>(initCompanyData);
  const { organisation, sector, teamSize } = state;
  const [error, setError] = useState<CompanyFormErrors>();

  useEffect(() => {
    initData && setState(initData);
  }, [initData]);

  const handleSubmit = () => {
    const errors: CompanyFormErrors = {};

    if (organisation.trim().length === 0) errors.organisation = "Required";
    if (!sector) errors.sector = "Required";
    if (!teamSize) errors.teamSize = "Required";

    if (Object.keys(errors).length > 0) {
      setError(errors);
    } else {
      submit(state);
    }
  };

  const sectorOptions: OptionType[] = [
    {
      label: "Education Travel",
      value: "Education Travel"
    },
    {
      label: "Health Tourism",
      value: "Health Tourism"
    },
    {
      label: "Tour & Migration",
      value: "Tour & Migration"
    }
  ];

  const teamSizeOptions: OptionType[] = [
    {
      label: "1-10",
      value: "1-10"
    },
    {
      label: "11-30",
      value: "11-30"
    },
    {
      label: "31-50",
      value: "31-50"
    },
    {
      label: "50+",
      value: "50+"
    }
  ];

  return (
    <div className={styles.content}>
      <Arrow
        role="button"
        className={styles.goBack}
        onClick={() => handleFormChange("personal", ["personal"])}
      />
      <CompanyInfo className={styles.icon} style={{ marginTop: -20 }} />
      <div className={styles.details}>
        <h2>Company information</h2>
        <p>Help us get to know your organization better</p>
      </div>
      <form className={styles.form}>
        <aside className={styles.formSection}>
          <div>
            <Input
              name="organisation"
              label="Organisation"
              placeholder="Enter organisation name"
              value={organisation}
              onChange={(e) => setState((prev) => ({ ...prev, organisation: e.target.value }))}
              error={error?.organisation}
            />
          </div>

          <div>
            <SelectInput
              name="sector"
              label="Sector"
              selectedOption={sector}
              options={sectorOptions}
              handleSelectChange={(value) =>
                value && setState((prev) => ({ ...prev, sector: value }))
              }
              placeholder={"Select sector"}
              error={error?.sector}
            />
          </div>
          <div>
            <SelectInput
              name="teamSize"
              label="Team size"
              selectedOption={teamSize}
              options={teamSizeOptions}
              handleSelectChange={(value) =>
                value && setState((prev) => ({ ...prev, teamSize: value }))
              }
              placeholder={"Select team size"}
              error={error?.teamSize}
            />
          </div>

          <Button variant="fill-blue" onClick={handleSubmit}>
            Continue
          </Button>
        </aside>
      </form>
    </div>
  );
};

interface FeatureFormData {
  features: string;
  agree: boolean;
}

interface FeatureFormErrors {
  features?: string;
  agree?: string;
}

const initFeatureData: FeatureFormData = {
  features: "",
  agree: false
};

interface FeatureFormProps {
  initData: FeatureFormData | undefined;
  submit: (data: FeatureFormData) => void;
}

const Features: React.FC<FeatureFormProps> = ({ initData, submit }) => {
  const { handleFormChange } = useEarlyAccessContext();

  const [state, setState] = useState<FeatureFormData>(initFeatureData);
  const { features, agree } = state;
  const [error, setError] = useState<FeatureFormErrors>();

  useEffect(() => {
    initData && setState(initData);
  }, [initData]);

  const handleSubmit = () => {
    const errors: FeatureFormErrors = {};

    if (features.trim().length === 0) errors.features = "Required";
    if (!agree) errors.features = "Please agree to proceed";

    if (Object.keys(errors).length > 0) {
      setError(errors);
    } else {
      submit(state);
    }
  };

  return (
    <div className={styles.content}>
      <Arrow
        role="button"
        className={styles.goBack}
        onClick={() => handleFormChange("company", ["personal", "company"])}
      />
      <FeaturesInfo className={styles.icon} style={{ marginTop: -20 }} />
      <div className={styles.details}>
        <h2>Feature requests</h2>
        <p>Help us know how best to meet your needs</p>
      </div>
      <form className={styles.form}>
        <aside className={styles.formSection}>
          <div>
            <TextArea
              name="features"
              label="What features are most important to you and why?"
              value={features}
              className={styles.textarea}
              placeholder="Enter a description..."
              onChange={(e) => setState((prev) => ({ ...prev, features: e.target.value }))}
              error={error?.features}
            />
          </div>
          <div className={styles.subscribe}>
            <Input
              label="I agree to receive newsletters and product updates"
              type="checkbox"
              className={styles.checkbox}
              checked={agree}
              error={error?.agree}
              onChange={() => setState((prev) => ({ ...prev, agree: !prev.agree }))}
            />
          </div>
          <Button disabled={!agree} variant="fill-blue" onClick={handleSubmit}>
            Submit
          </Button>
        </aside>
      </form>
    </div>
  );
};
