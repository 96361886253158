import { DetailedHTMLProps, HTMLAttributes } from "react";
import styles from "./styles.module.scss";

interface HeadingProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
  variant: "large" | "small";
}

const Title = (props: HeadingProps) => {
  const { variant, children, className } = props;
  return variant === "small" ? (
    <p {...props} className={`${className} ${styles.heading} ${styles[`heading--${variant}`]} `}>
      {children}
    </p>
  ) : (
    <h1 {...props} className={`${className} ${styles.heading} ${styles[`heading--${variant}`]} `}>
      {children}
    </h1>
  );
};

export { Title };
