import { Arrow, Linkedin, LogoColored } from "assets";
import styles from "./styles.module.scss";

import { Link } from "react-router-dom";

interface FooterLinkProps {
  text: string;
  url?: string;
  comingSoon?: boolean;
}
//to-do: replace social media links
const productItems: FooterLinkProps[] = [
  {
    text: "Pipeline",
    comingSoon: true
  },
  {
    text: "Sales",
    comingSoon: true
  },
  {
    text: "Books",
    comingSoon: true
  },
  {
    text: "Sendup",
    comingSoon: true
  }
];

const solutions: FooterLinkProps[] = [
  {
    text: "Student Recruitment",
    url: "/solutions/education-consultant"
  },
  {
    text: "Medical Travel",
    url: "/solutions/medical-travel"
  },
  {
    text: "Tour & Migration",
    url: "/solutions/tour-and-migration"
  }
];

const resources: FooterLinkProps[] = [
  {
    text: "About",
    url: "/about"
  },
  {
    text: "Blog",
    url: "/blog"
  }
];

const contacts: FooterLinkProps[] = [
  {
    text: "sales@vobb.io"
  },
  {
    text: "support@vobb.io"
  }
];

const Footer = () => {
  return (
    <>
      <footer className={`container ${styles.footer}`}>
        <div className={styles.section}>
          <div>
            <LogoColored />
          </div>
          <div className={styles.content}>
            <div className={styles.linkWrap}>
              <p>Products</p>
              <a
                href="http://atlas.vobb.io"
                target="_blank"
                rel="noopener noreferrer"
                className={`${styles.linkWrap__item} ${styles.liveItem}`}>
                <span className={styles["linkWrap__item--live"]}>Atlas</span>
                <span>
                  <Arrow className={styles.arrowAtlas} />
                </span>
              </a>
              {productItems.map((product) => (
                <div className={`${styles.linkWrap__item}`} key={product.text}>
                  <span>{product.text}</span>

                  <span className={styles["linkWrap__item--comingSoon"]}>Coming soon</span>
                </div>
              ))}
            </div>
            <div className={styles.linkWrap}>
              <p>Solutions</p>
              {solutions.map((item) => (
                <Link to={item.url || "/"} className={styles.linkWrap__item} key={item.text}>
                  <span>{item.text}</span>
                </Link>
              ))}
            </div>
            <div className={styles.linkWrap}>
              <p>Resources</p>
              {resources.map((item) => (
                <Link to={item.url || "/"} className={styles.linkWrap__item} key={item.text}>
                  <span>{item.text}</span>
                </Link>
              ))}
            </div>
            <div className={`${styles.linkWrap} ${styles.contact}`}>
              <p>Contact Us</p>
              {contacts.map((item) => (
                <a href={`mailto:${item.text}`} className={styles.linkWrap__item} key={item.text}>
                  <span>{item.text}</span>
                </a>
              ))}
            </div>
          </div>
        </div>

        <div className={styles.footnote}>
          <div className={styles.footnote__left}>
            {/* <a href="instagram.com" className={styles["footnote__left--item"]}>
              <Instagram />
            </a>
            <a href="twitter.com" className={styles["footnote__left--item"]}>
              <Twitter />
            </a> */}
            <a
              href="https://www.linkedin.com/company/vobb-io/"
              target="_blank"
              rel="noreferrer"
              className={styles["footnote__left--item"]}>
              <Linkedin />
            </a>
            {/* <a href="facebook" className={styles["footnote__left--item"]}>
              <Facebook />
            </a> */}
          </div>
          <div className={styles.footnote__right}>
            <p>© 2021 Vobb. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export { Footer };
