import { Title, Section } from "components";
import styles from "./styles.module.scss";
import { OverviewAutomateEdu } from "assets";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useRef, useState } from "react";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const GetStarted = () => {
  const slideUp1 = useRef(null);
  const slideUp2 = useRef(null);
  const slideUp3 = useRef(null);
  const slideUp4 = useRef(null);
  const slideLeft = useRef(null);
  const pinnedSectionRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  window.addEventListener("resize", () => setIsMobile(window.innerWidth <= 800));
  window.addEventListener("load", () => setIsMobile(window.innerWidth <= 800));

  useGSAP(() => {
    if (isMobile) return;
    const pinnedSection = pinnedSectionRef.current;
    // const scrollSection = scrollSectionRef.current;

    // Pinning the section
    gsap.to(pinnedSection, {
      scrollTrigger: {
        trigger: pinnedSection,
        pin: true,
        start: "top 15%",
        end: "+=50%", // Adjust this value to control the length of pinning
        scrub: true
      }
    });
  }, [isMobile]);

  const slideUpConfig = (ref) => {
    return {
      duration: 1,
      opacity: 0,
      y: 120,
      ease: "power1.out",
      willChange: "transform",
      scrollTrigger: {
        trigger: ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse"
        // markers: true
      }
    };
  };

  useGSAP(
    () => {
      if (!isMobile) return;
      gsap.from(slideUp1.current, slideUpConfig(slideUp1));
    },
    { dependencies: [isMobile], scope: slideUp1 }
  );
  useGSAP(
    () => {
      if (!isMobile) return;
      gsap.from(slideUp2.current, slideUpConfig(slideUp2));
    },
    { dependencies: [isMobile], scope: slideUp2 }
  );
  useGSAP(
    () => {
      if (!isMobile) return;
      gsap.from(slideUp3.current, slideUpConfig(slideUp3));
    },
    { dependencies: [isMobile], scope: slideUp3 }
  );
  useGSAP(
    () => {
      if (!isMobile) return;
      gsap.from(slideUp4.current, slideUpConfig(slideUp4));
    },
    { dependencies: [isMobile], scope: slideUp4 }
  );

  useGSAP(
    () => {
      gsap.from(slideLeft.current, {
        duration: 1,
        opacity: 0,
        x: -90,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideLeft.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideLeft }
  );

  return (
    <Section bgClassName={styles.bg} sectionClassName={styles.content}>
      <div ref={slideLeft} className={styles.contentTop}>
        <Title variant="small">Getting started</Title>
        <h2 className={styles.contentTop__bigHeader}>Powerhouse for all your business needs</h2>
        <p className={styles.contentTop__paragraph}>
          With our all-in-one software, we have eliminated the time, effort, and costs associated
          with running multiple business management softwares so you can get the most productivity.
        </p>
      </div>

      <div className={styles.contentBtm}>
        <div className={styles.contentBtm__left}>
          <div ref={slideUp1} className={`${styles["contentBtm__left--item"]} panel`}>
            <p className={styles.title} style={{ color: "#D11660" }}>
              1. Pipeline
            </p>
            <h3 className={styles.desc}>Capture leads</h3>
            <p className={styles.paragraph}>
              From lead generation, to scoring, to conversion, Vobb’s lead management tools ensures
              proper follow-up to move your patients through the sales pipeline.
            </p>
          </div>
          <div ref={slideUp2} className={`${styles["contentBtm__left--item"]} panel`}>
            <p className={styles.title} style={{ color: "#00C3A2" }}>
              2. Sales
            </p>
            <h3 className={styles.desc}>Manage contracts</h3>
            <p className={styles.paragraph}>
              Beautifully display your Clinics and treatments within your dashboard to enable your
              teams for best customer experience, better conversion, and insightful analytics.
            </p>
          </div>
          <div ref={slideUp3} className={`${styles["contentBtm__left--item"]} panel`}>
            <p className={styles.title} style={{ color: "#F4511E" }}>
              3. Sendup
            </p>
            <h3 className={styles.desc}>Automate marketing</h3>
            <p className={styles.paragraph}>
              From lead generation, to scoring, to conversion, Vobb’s lead management tools ensures
              proper follow-up to move your patients through the sales pipeline.
            </p>
          </div>
        </div>

        <div ref={isMobile ? slideUp4 : pinnedSectionRef} className={styles.contentBtm__right}>
          <img src={OverviewAutomateEdu} alt="overview" />
        </div>
      </div>
    </Section>
  );
};

export { GetStarted };
