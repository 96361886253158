import React from "react";
import { HeroSection } from "./heroSection";
import { LeadGeneration } from "./leadGeneration";
import DifferentiateAgency from "./differentiateAgency";
import { GetStarted } from "./gettingStarted";
import { Segmentation } from "./segmentation";
import { GrowBusiness } from "./growBusiness";
import { CaseStudies } from "./caseStudies";

const MedicalTravelsUI = () => {
  return (
    <>
      <HeroSection />
      <LeadGeneration />
      <DifferentiateAgency />
      <GetStarted />
      <Segmentation />
      <GrowBusiness />
      <CaseStudies />
    </>
  );
};

export { MedicalTravelsUI };
