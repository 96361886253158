import { Title, Section } from "components";
import styles from "./styles.module.scss";
import { AutoFinance, Configure, CreateInst, LevAtlas } from "assets";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const ManageBusiness = () => {
  const slideUp1 = useRef(null);
  const slideUp2 = useRef(null);
  useGSAP(
    () => {
      gsap.from(slideUp1.current, {
        duration: 1,
        opacity: 0,
        y: 120,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideUp1.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideUp1 }
  );

  useGSAP(
    () => {
      gsap.from(slideUp2.current, {
        duration: 1,
        opacity: 0,
        y: 120,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideUp2.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideUp2 }
  );

  return (
    <Section bgClassName={styles.bg} sectionClassName={styles.content}>
      <div ref={slideUp1} className={styles.contentTop}>
        <Title variant="small">Manage your business.</Title>
        <h2 className={styles.contentTop__bigHeader}>
          A birds-eye view of all your products, offerings and sales data.
        </h2>
        <p className={styles.contentTop__paragraph}>
          Collect, manage and oversee education travel facilitation for you students and partners
          from one setting.
        </p>
      </div>
      <div ref={slideUp2} className={styles.contentBtm}>
        <div className={styles.contentBtm__item}>
          <CreateInst />
          <Title variant="small" className={styles.title}>
            Create Institutions
          </Title>
          <p>Create your institutions and partners to be used by your teams.</p>
        </div>

        <div className={styles.contentBtm__item}>
          <Configure className={styles.image} />
          <Title variant="small" className={styles.title}>
            Configure commissions
          </Title>
          <p>Configure your contracts to stay ahead of everything finance.</p>
        </div>

        <div className={styles.contentBtm__item}>
          <AutoFinance className={styles.image} />
          <Title variant="small" className={styles.title}>
            Automate finance
          </Title>
          <p>
            Benefit from <span>Vobb Books</span> ability to reconcile your accounts automatically.
          </p>
        </div>

        <div className={styles.contentBtm__item}>
          <LevAtlas className={styles.image} />
          <Title variant="small" className={styles.title}>
            Leverage Atlas
          </Title>
          <p>
            Utilize the network of{" "}
            <a href="http://atlas.vobb.io" target="_blank" rel="noopener noreferrer">
              Vobb Atlas{" "}
            </a>
            to offer more to your customers.
          </p>
        </div>
      </div>
    </Section>
  );
};

export { ManageBusiness };
