import { EarlyAccessContextProvider } from "context";
import { LearnMore } from "./learnMore";
import { Steps } from "./steps";
import styles from "./styles.module.scss";

interface EarlyAccessLayoutProps {
  children: React.ReactNode;
}

const EarlyAccessLayout: React.FC<EarlyAccessLayoutProps> = ({ children }) => {
  return (
    <EarlyAccessContextProvider>
      <section className={styles.layoutBg}>
        <div className={styles.layout}>
          <Steps />
          <section>{children}</section>
          <LearnMore />
        </div>
      </section>
    </EarlyAccessContextProvider>
  );
};

export { EarlyAccessLayout };
