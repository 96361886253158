import { createContext, ReactNode, useContext, useRef, useState } from "react";

interface NavContextType {
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
  handleIndexChange: (index: number) => void;
  productRef: React.MutableRefObject<HTMLDivElement | null>;
  scrollToElement: () => void;
}

const defaultValue: NavContextType = {
  currentIndex: -1,
  setCurrentIndex: () => {},
  handleIndexChange: () => {},
  productRef: { current: null }, // Initialize ref object with null
  scrollToElement: () => {}
};

const NavContext = createContext<NavContextType>(defaultValue);

interface NavContextProviderProps {
  children: ReactNode;
}

export const useNavContext = () => {
  return useContext(NavContext);
};

export const NavContextProvider = ({ children }: NavContextProviderProps) => {
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const handleIndexChange = (index: number) => {
    setCurrentIndex(index);
  };
  const productRef = useRef<HTMLDivElement>(null); // Move useRef inside the component

  const scrollToElement = () => {
    productRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <NavContext.Provider
      value={{ currentIndex, setCurrentIndex, handleIndexChange, productRef, scrollToElement }}>
      {children}
    </NavContext.Provider>
  );
};
