import { Section, Title } from "components";
import styles from "./styles.module.scss";
import { VerticalBarchart } from "assets";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const Commissions = () => {
  const slideRight = useRef(null);
  const slideLeft = useRef(null);

  useGSAP(
    () => {
      gsap.from(slideRight.current, {
        duration: 1,
        opacity: 0,
        x: 90,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideRight.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideRight }
  );

  useGSAP(
    () => {
      gsap.from(slideLeft.current, {
        duration: 1,
        opacity: 0,
        x: -90,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideLeft.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideLeft }
  );

  return (
    <Section bgClassName={styles.bg} sectionClassName={styles.contentbg}>
      <div className={styles.contentTop}>
        <div ref={slideRight} className={styles.contentTop__left}>
          <Title variant="small">Embedded financial services</Title>
          <h2 className={styles.contentTop__bigHeader}>
            Earn more from a flexible commission package.{" "}
          </h2>
          <p className={styles.contentTop__paragraph}>
            Agencies receive 100% commissions for successful applications created through
            Vobb-Atlas, Bonuses and commissions are visible on the dashboard.
          </p>
          <a href="http://atlas.vobb.io" target="_blank" rel="noopener noreferrer">
            Learn More
          </a>
        </div>
        <VerticalBarchart ref={slideLeft} className={styles.contentTop__right} />
      </div>
    </Section>
  );
};

export { Commissions };
