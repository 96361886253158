import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { BlogCard, Input, Section, Spinner } from "components";
import { CaretUp, HotelsTour, salesImg } from "assets";
import { BlogType } from "../heroSection";

export interface BlogCategoriesProps {
  overviewData: BlogType[];
  searchQuery: string;
  setSearchQuery: (search: string) => void;
  currentPage: number;
  handlePagination: (direction: "next" | "prev") => void;
  hasNext: boolean;
}

const Categories: React.FC<BlogCategoriesProps> = ({
  overviewData: blogs,
  searchQuery,
  setSearchQuery,
  currentPage,
  hasNext,
  handlePagination
}) => {
  const [activeCategory, setActiveCategory] = useState("All");
  const categories = ["All", "Education", "Tour", "Medical"];

  const overviewData = blogs.slice(1, blogs.length);

  // Define the filteredData based on activeCategory and searchQuery
  const filteredData =
    activeCategory === "All"
      ? overviewData
      : overviewData
          ?.filter(
            (item) =>
              item?.Tags && item.Tags.some((tag) => tag.name === activeCategory.toLowerCase())
          )
          ?.filter((item) => {
            if (searchQuery.length === 3) {
              return item?.Title.toLowerCase().includes(searchQuery.toLowerCase());
            }

            return true;
          });

  return (
    <Section sectionClassName={styles.content} bgClassName={styles.bg}>
      <h2 className={styles.content__headline}>Browse the VBlog</h2>
      <p className={styles.content__ttl}>
        Want to go deeper? Click on a category below for more from the Vblog.
      </p>
      <div className={styles.content__top}>
        <aside className={styles.categories}>
          {categories.map((cat) => (
            <span
              key={cat}
              onClick={() => setActiveCategory(cat)}
              className={activeCategory === cat ? styles.active : ""}>
              {cat}
            </span>
          ))}
        </aside>
        <Input
          placeholder="Search by topic"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <div className={styles.content__btm}>
        {filteredData && filteredData.length > 0 ? (
          filteredData.map((data) => (
            <BlogCard
              key={data.id}
              id={data.id}
              topic={data.Title}
              title={data.Description}
              content={data.IntroParagraph}
              authorName={data.Author}
              authorRole={data.AuthorRole}
              authorCompany={data.AuthorCompany}
              imageUrl={data.Cover ?? HotelsTour}
              avatarUrl={data.AuthImg ?? salesImg}
            />
          ))
        ) : (
          <div>
            <p>No Data Available</p>
          </div>
        )}
      </div>
      <div className={styles.content__pagination}>
        <button
          disabled={currentPage < 2}
          className={styles.content__pagination__btn}
          onClick={() => handlePagination("prev")}>
          <CaretUp className={styles.caretLeft} />
        </button>

        <button
          disabled={!hasNext}
          className={styles.content__pagination__btn}
          onClick={() => handlePagination("next")}>
          <CaretUp className={styles.caretRight} />
        </button>
      </div>
    </Section>
  );
};

export { Categories };
