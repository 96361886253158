import { Title, Section } from "components";
import styles from "./styles.module.scss";
import { LevAtlas, MapIcon, Retarget, SegmentIcon } from "assets";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";

gsap.registerPlugin(useGSAP, ScrollTrigger);
const GrowBusiness = () => {
  const slideUp1 = useRef(null);
  const slideUp2 = useRef(null);
  const slideUp3 = useRef(null);
  const slideUp4 = useRef(null);
  const slideLeft = useRef(null);

  useGSAP(
    () => {
      gsap.from(slideUp1.current, {
        duration: 1,
        opacity: 0,
        y: 120,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideUp1.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideUp1 }
  );

  useGSAP(
    () => {
      gsap.from(slideUp2.current, {
        duration: 1,
        opacity: 0,
        y: 120,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideUp2.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideUp2 }
  );

  useGSAP(
    () => {
      gsap.from(slideUp3.current, {
        duration: 1,
        opacity: 0,
        y: 120,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideUp3.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideUp3 }
  );

  useGSAP(
    () => {
      gsap.from(slideUp4.current, {
        duration: 1,
        opacity: 0,
        y: 120,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideUp4.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideUp4 }
  );

  useGSAP(
    () => {
      gsap.from(slideLeft.current, {
        duration: 1,
        opacity: 0,
        x: -90,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideLeft.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideLeft }
  );
  return (
    <Section bgClassName={styles.bg} sectionClassName={styles.content}>
      <div ref={slideLeft} className={styles.contentTop}>
        <Title variant="small">Grow your business</Title>
        <h2 className={styles.contentTop__bigHeader}>
          Drive engagement and growth with customer segmentation
        </h2>
        <p className={styles.contentTop__paragraph}>
          Segment your patients into buckets based on a pre-defined criteria, to identify
          cross-sell/up-sell opportunities and execute targeted campaigns to increase engagement,
          improve retention and deliver better ROI.
        </p>
      </div>
      <div className={styles.contentBtm}>
        <div ref={slideUp1} className={styles.contentBtm__item}>
          <SegmentIcon />
          <p className={styles.title}>Create segments</p>
          <p>
            Create segments and watch SendUp automatically sort your patients through your sales
            pipeline.
          </p>
        </div>
        <div ref={slideUp2} className={styles.contentBtm__item}>
          <LevAtlas className={styles.image} />
          <p className={styles.title}>Use Insights</p>
          <p>
            Reporting helps you determine which campaigns are responsible for closing your specific
            patients.
          </p>
        </div>
        <div ref={slideUp3} className={styles.contentBtm__item}>
          <MapIcon className={styles.image} />
          <p className={styles.title}>Identify winners</p>
          <p>
            Any lead journey can involve multiple campaigns. Learning how they perform is paramount.
          </p>
        </div>
        <div ref={slideUp4} className={styles.contentBtm__item}>
          <Retarget className={styles.image} />
          <p className={styles.title}>Re-target.</p>
          <p>
            Design new email campaigns that will take conversion rates to new heights with latest
            insights.
          </p>
        </div>
      </div>
    </Section>
  );
};

export { GrowBusiness };
