import { Title, Section } from "components";
import styles from "./styles.module.scss";
import { Commissions, NewClinic, RecentFiles, TeamMgt } from "assets";
import { Link } from "react-router-dom";
import { useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const DifferentiateAgency = () => {
  const slideUp1 = useRef(null);
  const slideUp2 = useRef(null);
  const slideUp3 = useRef(null);
  const slideUp4 = useRef(null);
  const slideUp5 = useRef(null);

  const slideUpConfig = (ref) => {
    return {
      duration: 1,
      opacity: 0,
      y: 90,
      ease: "power1.out",
      willChange: "transform",
      scrollTrigger: {
        trigger: ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse"
        // markers: true
      }
    };
  };

  useGSAP(
    () => {
      gsap.from(slideUp1.current, slideUpConfig(slideUp1));
    },
    { scope: slideUp1 }
  );
  useGSAP(
    () => {
      gsap.from(slideUp2.current, slideUpConfig(slideUp2));
    },
    { scope: slideUp2 }
  );
  useGSAP(
    () => {
      gsap.from(slideUp3.current, slideUpConfig(slideUp3));
    },
    { scope: slideUp3 }
  );
  useGSAP(
    () => {
      gsap.from(slideUp4.current, slideUpConfig(slideUp4));
    },
    { scope: slideUp4 }
  );
  useGSAP(
    () => {
      gsap.from(slideUp5.current, slideUpConfig(slideUp5));
    },
    { scope: slideUp5 }
  );

  return (
    <Section bgClassName={styles.bg} sectionClassName={styles.content}>
      <div ref={slideUp1} className={styles.contentTop}>
        <Title variant="small">Differentiate your agency</Title>
        <h2 className={styles.contentTop__bigHeader}>
          Setup for lasting positive customer experince
        </h2>
        <p className={styles.contentTop__paragraph}>
          Create and share beautiful, captivating treatments, itineraries and quotes within minutes
          that will increase your conversion rates and positive customer experience.
        </p>
      </div>
      <div className={styles.contentBtm}>
        <div ref={slideUp2} className={styles.contentBtm__item}>
          <NewClinic className={styles.image} />

          <div>
            <p className={styles.title}>Set Clinics</p>
            <p className={styles.heading}>Create your partnered clinics</p>
            <p>
              Offerings allows you to setup your partnered clinics to be used by team members for
              internal operations and also synced with your{" "}
              <Link to={"early-access"}>finance dashboard</Link> to track revenue growth.
            </p>
          </div>
        </div>
        <div ref={slideUp3} className={styles.contentBtm__item}>
          <TeamMgt className={`${styles.image}`} />

          <div>
            <p className={styles.title}>assign treatments</p>
            <p className={styles.heading}>Assign treatment under each clinic</p>
            <p>
              You might be working with different clinics for different treatments and health
              services. You can also assign treatments to partnered clinics to allow you stay even
              more organized.
            </p>
          </div>
        </div>
        <div ref={slideUp4} className={styles.contentBtm__item}>
          <Commissions className={styles.image} />

          <div>
            <p className={styles.title}>manage contracts</p>
            <p className={styles.heading}>Configure commissions and mark-ups</p>
            <p>
              Whether you receive commissions from clinics for each of your patients, or you benefit
              from adding mark-ups to treatment costs, Vobb allows you to configure your contracts
              so you can easily keep track of earnings
            </p>
          </div>
        </div>
        <div ref={slideUp5} className={styles.contentBtm__item}>
          <RecentFiles className={`${styles.image}`} />

          <div>
            <p className={styles.title}>leverage storage</p>
            <p className={styles.heading}>Benefit from unlimited storage space</p>
            <p>
              Teamwide document management has never been simpler. Stop wasting valuable time
              tracking down important patient documents. Allow uploads through intelligent forms or
              manually store travelers documents.
            </p>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default DifferentiateAgency;
