import { MenuPortalData } from "layout/landing/navbar";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";

const DropdownItem: React.FC<MenuPortalData> = ({
  icon,
  url,
  title,
  paragraph,
  onClick,
  index
}) => {
  return (
    <>
      {index ? (
        <div className={styles["portalWrap__productItem"]} onClick={onClick}>
          <div className={styles["portalWrap__productItem--icon"]}>{icon}</div>
          <div className={styles["portalWrap__productItem--content"]}>
            <p>{title}</p>
            <p>{paragraph}</p>
          </div>
        </div>
      ) : (
        <Link to={url ?? "/"} className={styles["portalWrap__productItem"]} onClick={onClick}>
          <div className={styles["portalWrap__productItem--icon"]}>{icon}</div>
          <div className={styles["portalWrap__productItem--content"]}>
            <p>{title}</p>
            <p>{paragraph}</p>
          </div>
        </Link>
      )}
    </>
  );
};

export { DropdownItem };
