import { useEffect, useState } from "react";
import { AddressLocation, Map } from "assets";
import styles from "./styles.module.scss";

const positionDetailsDesktop: PersonLocationData[] = [
  {
    top: "53%",
    left: "51%",
    color: "1",
    location: "Lagos, Nigeria"
  },
  {
    top: "30%",
    left: "58%",
    color: "2",
    location: "Nicosia, Cyprus"
  },
  {
    top: "13%",
    left: "25%",
    color: "3",
    location: "Ontario, Canada"
  }
  // {
  //   top: "28.5%",
  //   left: "68.5%",
  //   color: "4",
  //   location: "Nicosia, Cyprus1"
  // },
  // {
  //   top: "79.5%",
  //   left: "59%",
  //   color: "5",
  //   location: "Nicosia, Cyprus2"
  // },
  // {
  //   top: "67%",
  //   left: "32.5%",
  //   color: "6",
  //   location: "Nicosia, Cyprus3"
  // },
  // {
  //   top: "36%",
  //   left: "81.5%",
  //   color: "7",
  //   location: "Nicosia, Cyprus4"
  // },
  // {
  //   top: "13%",
  //   left: "80.5%",
  //   color: "8",
  //   location: "Nicosia, Canada?"
  // }
];

const WorldMap = () => {
  const [documentWidth, setDocumentWidth] = useState(0);
  const [personLocationScale, setPersonLocationScale] = useState(1);
  const [currentPosition, setCurrentPosition] = useState(0);

  const increasePositionNo = () => {
    setCurrentPosition((position) => {
      if (position === positionDetailsDesktop.length - 1) return 0;
      else return position + 1;
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      increasePositionNo();
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const d = document.documentElement.clientWidth;
    setDocumentWidth(d);
  }, []);

  useEffect(() => {
    if (documentWidth <= 550) setPersonLocationScale(0.5);
    else if (documentWidth <= 800) setPersonLocationScale(0.8);
    else setPersonLocationScale(1);
  }, [documentWidth]);

  return (
    <div className={styles.globeWrap}>
      <Map className={styles.map} />
      <PersonLocation
        scale={personLocationScale}
        top={positionDetailsDesktop[currentPosition].top}
        color={positionDetailsDesktop[currentPosition].color}
        left={positionDetailsDesktop[currentPosition].left}
        location={positionDetailsDesktop[currentPosition].location ?? ""}
      />
    </div>
  );
};

export { WorldMap };

interface PersonLocationData {
  top: string;
  left: string;
  color: string;
  location: string;
}

interface PersonLocationProps extends PersonLocationData {
  scale: number;
}

const PersonLocation = ({ scale, top, left, color, location }: PersonLocationProps) => {
  return (
    <div
      style={{
        position: "absolute",
        top,
        left,
        color,
        transform: `scale(${scale}) translate(-50%, -50%)`,
        transition: "all 1s ease-in-out"
      }}
      className={styles.location}>
      <div className={styles.location__toolTip}>
        <AddressLocation />
        <h2>{location}</h2>
        {/* <p>
          18, Smith Street <br /> Collingwood VAC 2044 AQ
        </p> */}
      </div>

      <div className={styles.location__circle}></div>
    </div>
  );
};
