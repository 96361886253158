import { Spinner } from "components";
import { SingleBlogUI } from "modules/blog/singleBlog";
import { BlockType } from "notion-block-renderer";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_NOTION_URL;

const BlogDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blogDetails, setBlogDetails] = useState<BlockType[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchBlog = () => {
    if (!BASE_URL) {
      console.error("REACT_APP_NOTION_URL is not defined");
      return;
    }
    setLoading(true);
    fetch(`${BASE_URL}blog/${id}`)
      .then((res) => res.json())
      .then((payload) => {
        setBlogDetails(payload);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchBlog();
  }, [id]);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>{loading ? <Spinner /> : <SingleBlogUI handleBack={handleBack} blocks={blogDetails} />}</>
  );
};

export { BlogDetails };
