import NotionBlocks, { BlockType } from "notion-block-renderer";
import styles from "./styles.module.scss";
import { Section, Spinner } from "components";
import { Arrow } from "assets";

interface Props {
  blocks: BlockType[];
  handleBack: () => void;
}
const BlogDetails: React.FC<Props> = ({ blocks, handleBack }) => {
  return (
    <Section sectionClassName={styles.content} bgClassName={styles.bg}>
      <button className={styles.goBack} onClick={handleBack}>
        <Arrow /> Back
      </button>
      <div>
        {/* @ts-ignore */}
        <NotionBlocks blocks={blocks} />
      </div>
    </Section>
  );
};

export { BlogDetails };
