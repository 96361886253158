import { Section, Title } from "components";
import styles from "./styles.module.scss";
import { EmojiCheck, LocationTour1, LocationTour2 } from "assets";
import { useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const Remarket = () => {
  const slideUp1 = useRef(null);
  const slideUp2 = useRef(null);
  const slideUp3 = useRef(null);

  const slideUpConfig = (ref) => {
    return {
      duration: 1,
      opacity: 0,
      y: 90,
      ease: "power1.out",
      willChange: "transform",
      scrollTrigger: {
        trigger: ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse"
        // markers: true
      }
    };
  };

  useGSAP(
    () => {
      gsap.from(slideUp1.current, slideUpConfig(slideUp1));
    },
    { scope: slideUp1 }
  );
  useGSAP(
    () => {
      gsap.from(slideUp2.current, slideUpConfig(slideUp2));
    },
    { scope: slideUp2 }
  );
  useGSAP(
    () => {
      gsap.from(slideUp3.current, slideUpConfig(slideUp3));
    },
    { scope: slideUp3 }
  );

  return (
    <Section bgClassName={styles.bg} sectionClassName={styles.content}>
      <div ref={slideUp1} className={styles.contentTop}>
        <Title variant="small">Remarket your popular locations</Title>
        <h2 className={styles.contentTop__bigHeader}>
          Leverage pipeline and offerings sales data to optimize for growth.
        </h2>
        <p className={styles.contentTop__paragraph}>
          <strong>Pipeline</strong> already helps you capture customer interests, and helps you keep
          track of successful sales of products in your offerings catalog. Vobb operating systems
          allows you to resell your best performing offerings using <strong>SendUp</strong>.
        </p>
      </div>

      <div className={styles.contentBtm}>
        <div ref={slideUp2} className={styles.contentBtm__left}>
          <Title variant="small" className={styles.ttl}>
            Accurate and comprehensive individual offerings report.
          </Title>
          <p className={styles.paragraph}>
            Gain valuable insights on each product in your offerings catalog, insights like;
            interests over time, demographic majority, conversaion rates, revenue overtime and use
            them to make better informed marketing decisions.
          </p>
          <img src={LocationTour1} alt="location1" />
          <div className={styles.checks}>
            <p className={styles["checks__item"]}>
              <EmojiCheck />
              <span>Customize your offerings</span>
            </p>
            <p className={styles["checks__item"]}>
              <EmojiCheck />
              <span>Monitor and analyze performance.</span>
            </p>
            <p className={styles["checks__item"]}>
              <EmojiCheck />
              <span>Create beautifully hosted itineraries.</span>
            </p>
            <p className={styles["checks__item"]}>
              <EmojiCheck />
              <span>Keep track of sales and revenue.</span>
            </p>
          </div>
        </div>

        <div ref={slideUp3} className={styles.contentBtm__right}>
          <Title variant="small" className={styles.ttl}>
            Smart segmentation by interests for precise retargeting.
          </Title>
          <p className={styles.paragraph}>
            With the power of SendUp, you can re-target travellers that successfully bought an
            experience, or segment traveller who might be interested in specific products in your
            offerings catalog.
          </p>
          <img src={LocationTour2} alt="location1" />
          <div className={styles.checks}>
            <p className={styles["checks__item"]}>
              <EmojiCheck />
              <span>Create segments by interests</span>
            </p>
            <p className={styles["checks__item"]}>
              <EmojiCheck />
              <span>Market your most successful offerings. </span>
            </p>
            <p className={styles["checks__item"]}>
              <EmojiCheck />
              <span>Run precisely targeted marketing campaigns. </span>
            </p>
            <p className={styles["checks__item"]}>
              <EmojiCheck />
              <span>leverage customer activity data for optimization.</span>
            </p>
          </div>
        </div>
      </div>
    </Section>
  );
};

export { Remarket };
