import { Section, Title } from "components";
import styles from "./styles.module.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const CaseStudies = () => {
  const slideUp1 = useRef(null);
  const slideUp2 = useRef(null);

  useGSAP(
    () => {
      gsap.from(slideUp1.current, {
        duration: 1,
        opacity: 0,
        y: 120,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideUp1.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideUp1 }
  );

  useGSAP(
    () => {
      gsap.from(slideUp2.current, {
        duration: 1,
        opacity: 0,
        y: 120,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideUp2.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideUp2 }
  );
  return (
    <Section bgClassName={styles.bg} sectionClassName={styles.contentbg}>
      <Title variant="small">Case Study</Title>
      <div className={styles.content}>
        <div ref={slideUp1} className={styles.content__left}>
          <h4 className={styles.content__bigHeader}>
            MerveHealth uses Vobb to Manage their patients and clinics.
          </h4>
          <div className={styles.content__badges}>
            <span>Vobb Visa</span>
            <span>Sendup</span>
            <span>Pipeline</span>
            <span>Finance</span>
            <span>Miles</span>
          </div>
        </div>
        <div ref={slideUp2} className={styles.content__right}>
          <Title variant="small" className={styles["content__right--title"]}>
            MerveHealth is a Medical tourism facilitator that specializes in arranging patients
            travels for international medical procedures.
          </Title>
          <div className={styles.challenges}>
            <Title variant="small" className={styles["content__right--title2"]}>
              The Challenge
            </Title>
            <p>
              Up until now we had not been able to adequately manage patients data and documents and
              had to resort to spreadsheets, notepads and physical prints to stay in sync.
            </p>
            <p>
              We have also had to work with various partners internationally to provide us other
              services for when our patients arrive for treatments.
            </p>
          </div>
          <div className={styles.results}>
            <Title variant="small" className={styles["content__right--title2"]}>
              The Results
            </Title>
            <p>
              Our first remark with Vobb was how it literally cut down our workload and takes
              control of the facilitation process from start to finish, giving us a 360 view of all
              our patients travel itenerary.
            </p>
            <p>
              We can now easily book Visas, flight tickets and accommodations for our patients and
              monitor it all from our dashboards.
            </p>
          </div>

          <div className={styles.stats}>
            <div>
              <Title variant="small" className={styles["content__right--title3"]}>
                275%
              </Title>
              <p>Team increase since using started using vobb.</p>
            </div>
            <div>
              <Title variant="small" className={styles["content__right--title3"]}>
                360°
              </Title>
              <p>Travel facilitation services offered to patients.</p>
            </div>
            <div>
              <Title variant="small" className={styles["content__right--title3"]}>
                $300
              </Title>
              <p>Average realised earnings per patient across facilitation tools.</p>
            </div>
            <div>
              <Title variant="small" className={styles["content__right--title3"]}>
                40%
              </Title>
              <p>Increase in patient turn-around rate with email marketing.</p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export { CaseStudies };
