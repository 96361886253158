/**
 * ROUTES
 *
 * ===============================================
 *
 * This object depicts the component url structure.
 * It contains a key-value pair of components and their respective URLs
 *
 */

export const Routes = {
  // General
  home: "/",
  about: "/about",
  earlyAccess: "/early-access",
  completed: "/completed",
  educationAgency: "/solutions/education-consultant",
  medicalAgency: "/solutions/medical-travel",
  tourAgency: "/solutions/tour-and-migration",
  blog: "/blog",
  blogDetails: "/blog/:id"
};
