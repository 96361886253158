import { AutomateAgency } from "./automateAgency";
import { CaseStudies } from "./caseStudies";
import { Commissions } from "./commissions";
import { HeroSection } from "./heroSection";
import { LeadGeneration } from "./leadGeneration";
import { ManageBusiness } from "./manageBusiness";
import { ScaleGlobally } from "./scaleGlobally";
import { Segmentation } from "./segmentation";
import UnitedPlatform from "./unifiedPlatform";

const EducationConsultantUI = () => {
  return (
    <>
      <HeroSection />
      <LeadGeneration />
      <UnitedPlatform />
      <ManageBusiness />
      <AutomateAgency />
      <Segmentation />
      <ScaleGlobally />
      <Commissions />
      <CaseStudies />
    </>
  );
};

export { EducationConsultantUI };
