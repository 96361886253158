import { Faq } from "./faq";
import { GlobalScale } from "./globalScale";
import { HeroSection } from "./heroSection";
import { ProductSuites } from "./productSuites";
import { TravelAgents } from "./travelAgents";
import { UnitedPlatform } from "./unitedPlatform";
import { WhyVobb } from "./whyVobb";
// import styles from './styles.module.scss';

const HomeUI = () => {
  return (
    <>
      <HeroSection />
      <UnitedPlatform />
      <TravelAgents />
      <WhyVobb />
      <ProductSuites />
      <GlobalScale />
      {/* <Faq /> */}
    </>
  );
};

export { HomeUI };
