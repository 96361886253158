import { Title, Section } from "components";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { Routes } from "router";
import { MobileEdu } from "assets";
import { useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const HeroSection = () => {
  const zoomIn = useRef(null);
  const slideUp = useRef(null);

  useGSAP(
    () => {
      gsap.from(slideUp.current, {
        duration: 1,
        opacity: 0,
        y: 90,
        ease: "power1.out",
        willChange: "transform"
      });
    },
    { scope: slideUp }
  );

  useGSAP(
    () => {
      gsap.from(zoomIn.current, {
        duration: 1,
        scaleX: 0.5,
        scaleY: 0.5,
        ease: "power1.out",
        willChange: "transform"
      });
    },
    { scope: zoomIn }
  );


  return (
    <Section bgClassName={styles.bg} sectionClassName={styles.contentbg}>
      <div ref={slideUp} className={styles.contentTop}>
        <Title variant="small">Education Consultant</Title>
        <Title variant="large" className={styles.contentTop__bigHeader}>
          Launch and Scale <br /> <span>Student Recruitment</span> Agencies
        </Title>
        <p className={styles.contentTop__paragraph}>
          Vobb provides a unified workspace that does all the heavy lifting from onboarding, school
          research, application and document management to ticket booking so you don’t have to.{" "}
        </p>
        <div className={styles.contentTop__btns}>
          <Link to={Routes.earlyAccess} className={styles["contentTop__btns--earlyAccess"]}>
            Get early access
          </Link>
          <a
            href="http://atlas.vobb.io"
            target="_blank"
            rel="noopener noreferrer"
            className={styles["contentTop__btns--atlas"]}>
            Visit Atlas
          </a>
        </div>
      </div>

      <div ref={zoomIn} className={styles.contentMobile}>
        <MobileEdu />
      </div>
    </Section>
  );
};

export { HeroSection };
