import { CompanyInfo, FeaturesInfo, LogoColored, Mail, PersonalInfo } from "assets";
import styles from "./styles.module.scss";
import { useEarlyAccessContext } from "context";
import { Link } from "react-router-dom";
import { Routes } from "router";

const Steps = () => {
  const { activeForm, completedForms } = useEarlyAccessContext();
  return (
    <div className={styles.bg}>
      <div className={styles.content}>
        <Link className={styles.content__logo} to={Routes.home}>
          <LogoColored />
        </Link>

        <div className={styles.content__stepWrap} >
          <div className={`${styles.content__step} ${activeForm === "personal" ? styles.activeStep : ""}`}>
            <PersonalInfo
              className={completedForms.includes("personal") ? styles.completedPersonal : ""}
            />
            <div className={`${styles.details} ${activeForm === "personal" ? styles.active : ""}`}>
              <p>Personal information</p>
              <span>Please provide your name and email</span>
            </div>
          </div>

          <div className={`${styles.content__step} ${activeForm === "company" ? styles.activeStep : ""}`}>
            <CompanyInfo
              className={completedForms.includes("company") ? styles.completedCompany : ""}
            />
            <div className={`${styles.details} ${activeForm === "company" ? styles.active : ""}`}>
              <p>Company information</p>
              <span>A few details about your company</span>
            </div>
          </div>

          <div className={`${styles.content__step} ${activeForm === "features" ? styles.activeStep : ""}`}>
            <FeaturesInfo />
            <div className={`${styles.details} ${activeForm === "features" ? styles.active : ""}`}>
              <p>Feature requests</p>
              <span>What would you like to see in the OS?</span>
            </div>
          </div>
        </div>

        <div className={styles.content__btm}>
          <span>© Vobb 2024</span>
          <a href="mailto:support@vobb.io">
            <Mail /> support@vobb.io
          </a>
        </div>
      </div>
    </div>
  );
};

export { Steps };
