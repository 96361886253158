import React from "react";
import styles from "./styles.module.scss";
import { Section } from "components";
import { Link } from "react-router-dom";
import { shortenText } from "helpers";
import { MountNelson, booksImg } from "assets";

export interface BlogType {
  id: string;
  Title: string;
  Author: string;
  Cover?: string;
  AuthImg?: string;
  Date?: string;
  Description?: string;
  AuthorCompany?: string;
  AuthorRole?: string;
  Status?: string;
  IntroParagraph?: string;
  Tags?: {
    id: string;
    name: string;
    color: string;
  }[];
}

interface Props {
  overviewData: BlogType[];
}

const HeroSection: React.FC<Props> = ({ overviewData }) => {
  return (
    <Section sectionClassName={styles.section} bgClassName={styles.bg}>
      <Link to={`/blog/${overviewData?.[0]?.id}`} className={styles.content}>
        <img src={overviewData?.[0]?.Cover ?? MountNelson} alt="" className={styles.cover} />
        <aside>
          <p className={styles.paragraph}>{overviewData?.[0]?.Title}</p>
          <h1 className={styles.heading}>{overviewData?.[0]?.Description}</h1>
          <p className={styles.body}>{shortenText(overviewData?.[0]?.IntroParagraph, 200)}</p>
          <div className={styles.author}>
            <img
              src={overviewData?.[0]?.AuthImg ?? booksImg}
              alt={overviewData?.[0]?.Title}
              className={styles.avatar}
            />
            <div>
              <p className={styles.author__name}>{overviewData?.[0]?.Author ?? ""}</p>
              <p className={styles.author__role}>
                <span>{overviewData?.[0]?.AuthorRole}</span> @{" "}
                <span>{overviewData?.[0]?.AuthorCompany}</span>
              </p>
            </div>
          </div>
        </aside>
      </Link>
    </Section>
  );
};

export { HeroSection };
