import { Title, Section, Button } from "components";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { Routes } from "router";
import { useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const UnitedPlatform = () => {
  const slideUp = useRef(null);

  useGSAP(
    () => {
      gsap.from(slideUp.current, {
        duration: 1,
        opacity: 0,
        y: 120,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideUp.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideUp }
  );

  return (
    <Section innerRef={slideUp} bgClassName={styles.bg} sectionClassName={styles.content}>
      <Title variant="small">United platform</Title>
      <h2 className={styles.content__h2}>A Robust suite of products enabling your business.</h2>

      <div className={styles.article}>
        <article className={styles.article__left}>
          <p>
            Vobb is an all-in-one super platform with a carefully curated set of tools and
            integrations designed for travel agents to deliver a lasting experience for their
            clients.
          </p>
          <p>
            Each tool on the platform is effective on its own, their combined use creates a truly
            magical experience.
          </p>
        </article>
        <article className={styles.article__right}>
          <p>
            Vobb's travel operating system is well-suited for small business and enterprise
            organizations alike; empowering large and small sales teams to be more effective.
          </p>
          <p>
            CRM features like a <strong>Pipeline, Group Travel, Sales,</strong> and{" "}
            <strong>Automated Marketing</strong> with <strong>Smart Segmentation.</strong>
          </p>
        </article>
      </div>
      <Link className={styles.content__btn} to={Routes.earlyAccess}>
        Get early access
      </Link>
    </Section>
  );
};

export { UnitedPlatform };
