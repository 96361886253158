import { Title, Section } from "components";
import styles from "./styles.module.scss";
import { EmojiCheck, Lead } from "assets";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const LeadGeneration = () => {
  const slideRight = useRef(null);
  const slideLeft = useRef(null);

  useGSAP(
    () => {
      gsap.from(slideRight.current, {
        duration: 1,
        opacity: 0,
        x: 90,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideRight.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideRight }
  );

  useGSAP(
    () => {
      gsap.from(slideLeft.current, {
        duration: 1,
        opacity: 0,
        x: -90,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideLeft.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideLeft }
  );
  return (
    <Section bgClassName={styles.bg} sectionClassName={styles.contentbg}>
      <div className={styles.contentBtm}>
        <div ref={slideLeft} className={styles.contentBtm__left}>
          <Title variant="small">Client inquiry form</Title>
          <h2 className={styles.contentBtm__bigHeader}>Take control with intelligent forms.</h2>
          <p className={styles.contentBtm__paragraph}>
            Never miss out on any high-intent patients visiting your website. Pipeline captures
            leads with a customisable and sharable form link hosted on your Vobb account, without
            typing a single line of code.
          </p>

          <div className={styles.contentBtm__checks}>
            <p className={styles["contentBtm__checks--item"]}>
              <EmojiCheck />
              <span>Speed up the approval process with built in workflows</span>
            </p>
            <p className={styles["contentBtm__checks--item"]}>
              <EmojiCheck />
              <span>Capture and evaluate valuable lead data in real-time.</span>
            </p>
            <p className={styles["contentBtm__checks--item"]}>
              <EmojiCheck />
              <span>Quickly build forms with pre-built form actions.</span>
            </p>
            <p className={styles["contentBtm__checks--item"]}>
              <EmojiCheck />
              <span>Improve prospects onboarding experience.</span>
            </p>
          </div>
        </div>
        <Lead ref={slideRight} className={styles.contentBtm__right} />
      </div>
    </Section>
  );
};

export { LeadGeneration };
