import React from "react";
import { Link } from "react-router-dom";
import { shortenText } from "helpers";
import styles from "./styles.module.scss";

interface BlogCardContent {
  topic: string;
  title?: string;
  id: string;
  imageUrl?: string;
  content?: string;
  authorName?: string;
  authorRole?: string;
  avatarUrl?: string;
  authorCompany?: string;
}

const BlogCard: React.FC<BlogCardContent> = ({
  topic,
  title,
  content,
  authorName,
  authorRole,
  imageUrl,
  avatarUrl,
  authorCompany,
  id
}) => {
  return (
    <Link to={`/blog/${id}`} className={styles.card}>
      <img src={imageUrl} alt="mobile" className={styles.coverImg} />
      <p className={styles.topic}>{topic}</p>
      <p className={styles.title}>{title}</p>
      {content && <p className={styles.content}>{shortenText(content ?? "", 100)}</p>}{" "}
      {authorName && (
        <div className={styles.author}>
          <img src={avatarUrl} alt="avatar" className={styles.avatar} />
          <div>
            <p className={styles.author__name}>{authorName}</p>
            <p className={styles.author__role}>
              <span>{authorRole}</span> @ <span>{authorCompany}</span>
            </p>
          </div>
        </div>
      )}
    </Link>
  );
};

export { BlogCard };
