import { Title, Section } from "components";
import styles from "./styles.module.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const Agents = () => {
  const slideRight = useRef(null);
  const slideLeft = useRef(null);

  useGSAP(
    () => {
      gsap.from(slideRight.current, {
        duration: 1,
        opacity: 0,
        x: -90,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideRight.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideRight }
  );

  useGSAP(
    () => {
      gsap.from(slideLeft.current, {
        duration: 1,
        opacity: 0,
        x: 90,
        ease: "power1.out",
        willChange: "transform",
        scrollTrigger: {
          trigger: slideLeft.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse"
          // markers: true
        }
      });
    },
    { scope: slideLeft }
  );
  return (
    <>
      <Section bgClassName={styles.aboutBg} sectionClassName={styles.about}>
        <div className={styles.about__top}>
          <div ref={slideRight} className={styles["about__top--left"]}>
            <Title variant="small">About us</Title>
            <h2 className={styles.about__bigHeader}>
              The things we do, <br /> we do with
              <strong> Travel Agents</strong> in mind.
            </h2>
          </div>

          <p ref={slideLeft} className={styles["about__top--right"]}>
            Travel agents and agencies play an integral role in the worlds International traffic;
            with this great undertaken comes unique challenges both internal and external. Our goal
            is to enable travel agents efficiently facilitate travels.
          </p>
        </div>
        {/* <div className={styles.about__btm}>
          <div className={styles["about__btm--left"]}>
            <div className={styles.item}>
              <h2 className={styles.item__title}>25000+</h2>
              <Title variant="small" className={styles.item__subTitle}>
                Travel Agencies
              </Title>
              <p>Use Vobb to manage their customers and teams.</p>
            </div>
            <div className={styles.item}>
              <h2 className={styles.item__title}>300+</h2>
              <Title variant="small" className={styles.item__subTitle}>
                Airlines
              </Title>
              <p>Accessible through our in-app flight booking engine.</p>
            </div>
          </div>
          <div className={styles["about__btm--right"]}>
            <div className={styles.item}>
              <h2 className={styles.item__title}>450+</h2>
              <Title variant="small" className={styles.item__subTitle}>
                Universities
              </Title>
              <p>
                For education travel agents through our{" "}
                <a href="http://atlas.vobb.io" target="_blank" rel="noopener noreferrer">
                  Atlas service
                </a>{" "}
              </p>
            </div>
            <div className={styles.item}>
              <h2 className={styles.item__title}>1m+</h2>
              <Title variant="small" className={styles.item__subTitle}>
                Customers
              </Title>
              <p>
                Acquired and managed through our <strong>pipeline features.</strong>
              </p>
            </div>
          </div>
        </div> */}
      </Section>
    </>
  );
};

export { Agents };
