import { globeImg, Location4 } from "assets";
import styles from "./styles.module.scss";

const Globe = ({ ref }) => {
  return (
    <div ref={ref} className={styles.wrapper}>
      <img className={styles.globe} src={globeImg} alt="globe" />
      <Location4 className={styles.marker1} />
      <Location4 className={styles.marker2} />
      <Location4 className={styles.marker3} />
      <Location4 className={styles.marker4} />
      <Location4 className={styles.marker5} />
    </div>
  );
};

export { Globe };
